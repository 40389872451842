import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

const TocPage = () => (
  <Layout>
    <SEO title="Table of Contents | Building GitHub Actions" />
    <Nav />
    <div className="px-10 mt-12 mx-auto xl:w-2/3 pb-12">
      <h1 className="text-3xl mb-6">Table of Contents</h1>

      <section class="text-gray-700 body-font lg:flex w-full">
        <div class="container md:px-5 mx-auto flex flex-wrap">
          <div class="flex flex-wrap w-full">
            <div class="md:pr-10 xl:pt-6">
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  1
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Introduction
                  </h2>
                  <p class="leading-relaxed">
                    Learn what GitHub actions are and how they can be used. You
                    might be surprised, but you can do more than just running CI
                    scripts!
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  2
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Creating an action
                  </h2>
                  <p class="leading-relaxed">
                    Learn where Actions live on GitHub, what files are required
                    and how to build your first action by following a
                    step-by-step guide
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  3
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Using your action
                  </h2>
                  <p class="leading-relaxed">
                    Build a workflow that runs the action that you built in
                    response to events on GitHub. Learn how to handle events
                    such as <code>pull_request</code> and <code>push</code> and
                    understand the information available
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  4
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Running your Action locally
                  </h2>
                  <p class="leading-relaxed">
                    The <em>commit, push, wait</em> cycle gets repetitive very
                    quickly. Run your actions locally to reduce the time it
                    takes to get feedback and iterate on your workflows
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  5
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Build a real-world action using Bash
                  </h2>
                  <p class="leading-relaxed">
                    Understand how things work under the hood by building an
                    action that interacts with the GitHub API and data in{" "}
                    <code>event.json</code> to automatically add comments when
                    pull requests are merged
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  6
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Building with Node.js
                  </h2>
                  <p class="leading-relaxed">
                    Reimplement the Action from the previous chapter using
                    Node.js, taking advantage of <code>@actions/core</code> and{" "}
                    <code>@actions.github</code> to reduce the amount of code
                    you have to write
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  7
                </div>
                <div class="lg:hidden h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Useful Libraries
                  </h2>
                  <p class="leading-relaxed">
                    Make the most of the GitHub Actions ecosystem, using third
                    party libraries to reduce the amount of code you need to
                    write even further!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container md:px-5 mx-auto flex flex-wrap">
          <div class="flex flex-wrap w-full">
            <div class="md:pr-10 xl:pt-6">
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  8
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Writing Unit Tests
                  </h2>
                  <p class="leading-relaxed">
                    Your action's working, congratulations! But how do you know
                    it'll continue to work? How does it handle the edge cases?
                    Learn how to write unit tests for your action by mocking
                    both the <code>event.json</code> and GitHub API
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  9
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Action Runtimes
                  </h2>
                  <p class="leading-relaxed">
                    You can run your action using either the JavaScript or
                    Docker runtimes. Learn about the pros and cons of both
                    options and what the best option is for you
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  10
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Publishing your action
                  </h2>
                  <p class="leading-relaxed">
                    Writing an action isn't where the story ends. Once your
                    action is built, it's time to publish it! Learn what you
                    need to make your action official and publish it in the
                    GitHub Marketplace
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  11
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Bonus Action!
                  </h2>
                  <p class="leading-relaxed">
                    Extra bonus action! Learn how to build an action that runs
                    on a schedule to create an issue every week summarising the
                    activity in a repository
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  12
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Actions Tips and Tricks
                  </h2>
                  <p class="leading-relaxed">
                    I learned the hard way so that you don't have to. Learn
                    about debugging, semantic versioning, secret management and
                    more!
                  </p>
                </div>
              </div>
              <div class="flex relative">
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-teal-500 inline-flex items-center justify-center text-white relative z-10">
                  13
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="uppercase font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Conclusion
                  </h2>
                  <p class="leading-relaxed">
                    Congratulations, you made it to the end! By now you'll know
                    that Actions are for more than just Continuous Integration.
                    You've built multiple actions, configured workflows and
                    released them to the marketplace. I'll leave you with some
                    ideas for even more actions to build to enhance your skills
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default TocPage
