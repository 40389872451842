import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy | Building GitHub Actions" />
    <Nav />
    <div className="px-10 mt-12 mx-auto xl:w-2/3 ">
      <h1 className="text-3xl mb-6">Privacy Policy</h1>

      <h2 className="mt-6 text-2xl">Purchases</h2>
      <p className="mt-4">
        All personal data provided when making a purchase is handled by Gumroad
        as our payment processor. No information is transmitted to this website.
      </p>
      <p className="mt-4">
        For more information on how Gumroad uses this information see their{" "}
        <a href="https://gumroad.com/privacy">Privacy Policy</a>.
      </p>

      <h2 className="mt-6 text-2xl">Hotjar</h2>
      <p className="mt-4">
        We use Hotjar in order to better understand our users' needs and to
        optimise this service and experience. Hotjar is a technology service
        that helps us better understand our users' experience (e.g. how much
        time they spend on which pages, which links they choose to click, what
        users do and don’t like, etc.) and this enables us to build and maintain
        our service with user feedback. Hotjar uses cookies and other
        technologies to collect data on our users’ behavior and their devices.
        This includes a device's IP address (processed during your session and
        stored in a de-identified form), device screen size, device type (unique
        device identifiers), browser information, geographic location (country
        only), and the preferred language used to display our website. Hotjar
        stores this information on our behalf in a pseudonymised user profile.
        Hotjar is contractually forbidden to sell any of the data collected on
        our behalf.
      </p>

      <p className="mt-4">
        For further details, please see the ‘about Hotjar’ section of{" "}
        <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">
          Hotjar’s support site.
        </a>
      </p>

      <h2 className="mt-6 text-2xl">Google Analytics</h2>
      <p className="mt-4">
        Some information — such as IP address and/or browser and device
        characteristics — is collected automatically when you visit this site.
        This information does not track you as an individual, but we use browser
        and device characteristics to help determine the effectiveness of this
        site by identifying usage trends.
      </p>
      <p className="mt-4">
        This information will not be shared with any third parties, and will not
        be used for advertising purposes.
      </p>
    </div>
  </Layout>
)

export default PrivacyPage
