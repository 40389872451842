import React from "react"
import Row from "../components/gray-row"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Cta = ({ siteTitle }) => (
  <Row width="1200" category="cta">
    <div className="container px-5 py-6 mx-auto flex flex-wrap items-center">
      <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
        <h4 className="title-font font-medium text-3xl text-gray-900">
          Ready to purchase?
        </h4>
        <p className="leading-relaxed mt-4">
          Buy the screencasts now and save $30 on the regular
          price!
        </p>
        <p className="leading-relaxed mt-4">
          You'll get access to the book *and* the screencasts <em>today</em>
        </p>
      </div>
      <div className="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        <a
          href="#purchase"
          className="text-white bg-teal-500 border-0 py-2 px-4 focus:outline-none hover:bg-teal-600 rounded text-xl text-center"
          onClick={() =>
            trackCustomEvent({
              category: "cta",
              action: "click",
              label: "footer",
            })
          }
        >
          Buy it now for $69
        </a>
      </div>
    </div>
  </Row>
)

export default Cta
