import React from "react"

import { TwitterTweetEmbed } from "react-twitter-embed"
import Row from "../components/white-row"

import Typist from "react-typist"
import TypistLoop from "react-typist-loop"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const MoreThanCi = ({ siteTitle }) => (
  <Row category="more-than-ci">
    <div
      className="leading-24 text-xl md:text-4xl text-center h-12 md:h-32
    "
    >
      <TypistLoop interval={3000}>
        {[
          {
            if: "a pull request is opened",
            then: "launch a review application",
          },
          {
            if: "a deployment fails",
            then: "attach the logs as a comment",
          },
          {
            if: "a new issue is raised by a sponsor",
            then: "apply the 'urgent' label",
          },
          {
            if: "there's a commit to master",
            then: "tag a new release",
          },
          {
            if: "this",
            then: "that",
          },
          {
            if: "an issue is closed by the author",
            then: "apply the closed-by-author label",
          },
          {
            if: "an issue is closed by a team member",
            then: "apply the closed-by-team label",
          },
          {
            if: "we merge to master in repo X",
            then: "update the submodule in repo Y",
          },
          {
            if: "an issue doesn't change the default template",
            then: "add a comment asking for more information",
          },
          {
            if: "it's midnight",
            then: "close any stale issues",
          },
          {
            if: "you want to learn how to do this yourself",
            then: "buy this book",
          },
        ].map(text => (
          <Typist
            key={text.if}
            startDelay={500}
            onClick={() =>
              trackCustomEvent({
                category: "typist",
                action: "click",
                label: text.if,
              })
            }
          >
            <strong>If</strong> <span className="if-then">{text.if}</span>
            <br />
            <strong>Then</strong> <span className="if-then">{text.then}</span>
          </Typist>
        ))}
      </TypistLoop>
    </div>

    <p className="leading-8 mt-16">
      It's like If <span className="italic">This</span> Then{" "}
      <span className="italic weight-bold">That</span>, but for GitHub!
    </p>

    <p className="leading-8 mt-8">
      Sure, you can use it to run your tests when a pull request is opened, but
      there's so much more you can do.
    </p>

    <div
      className="flex justify-center w-full overflow-hidden mt-8"
      onClick={() =>
        trackCustomEvent({
          category: "twitter",
          action: "click",
        })
      }
    >
      <TwitterTweetEmbed tweetId={"1254124824298954752"} />
    </div>

    <p className="leading-8 mt-8">
      You specify which GitHub events you’re interested in and how you want your
      workflow to respond to those events. GitHub manages the execution of that
      workflow, provides rich feedback and, better still, lets you do this using
      your favourite programming language.
    </p>
  </Row>
)

export default MoreThanCi
