import React from "react"
import EmailListForm from "./email-list"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const HeaderLeft = ({ siteTitle }) => (
  <>
    <div id="subscribe" className="sm:text-center xl:text-left">
      <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
        Available Now
      </div>
      <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-6xl sm:leading-none">
        Complex workflows <br className="" />
        <span className="text-teal-500">slowing you down?</span>
      </h2>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0">
        Learn how to build GitHub Actions to solve the issues you hit every
        single day, using any programming language you like
      </p>
    </div>
    <EmailListForm />
    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0">
      Or{" "}
      <a
        href="#purchase"
        className="border-b-4 border-teal-400"
        onClick={() =>
          trackCustomEvent({
            category: "cta",
            action: "click",
            label: "header",
          })
        }
      >
        buy it now
      </a>{" "}
      if you're ready
    </p>
  </>
)

export default HeaderLeft
