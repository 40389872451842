import React from "react"

import Row from "../components/white-row"

const AuthorNote = ({ siteTitle }) => (
  <Row category="author-note">
    <h2 className="text-center w-full text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-6xl sm:leading-none mb-10">
      Building <br className="xl:hidden" />
      <span className="text-teal-600">GitHub Actions</span>
    </h2>

    <p className="text-xl leading-8">
      Hello{" "}
      <span role="img" aria-label="waving hand">
        👋
      </span>
    </p>

    <p className="text-xl leading-8 mt-8">
      I'm Michael and I <strong>love</strong> GitHub Actions. I work on a team
      that's built a complex platform to solve <em>exactly</em> the problems we
      need it to. Of course, this comes at a cost.
    </p>

    <p className="text-xl leading-8 mt-8">
      Data is stored across multiple repos, with various submodules and test
      suites that all need to stay in sync. People were struggling to make
      changes because our workflows were... well, they made doing your taxes
      look fun. They were repetitive, yet somehow still error prone at the same
      time.
    </p>

    <p className="text-xl leading-8 mt-8">
      Then GitHub Actions came along and solved all our problems. What{" "}
      <em>was</em> a complex process involving commits to multiple repos
      suddenly became as simple as merging a pull request. The time taken to
      validate changes and get them merged dropped from{" "}
      <strong>20 minutes</strong> to <strong>60 seconds</strong> overnight.
    </p>

    <p className="text-xl leading-8 mt-8">
      That was back in October 2018. Since then, I've built over a dozen GitHub
      Actions to automate our workflows with even more in the works. I've
      learned which libraries to use when building Actions, how to test them
      locally and how to build tools to make building Actions even easier. Now,
      I want to show you how to do the same.
    </p>
  </Row>
)

export default AuthorNote
