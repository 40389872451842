import React from "react"
import Row from "../components/white-row"

const Faq = ({ siteTitle }) => (
  <Row width="1200" category="faq">
    <div className="container px-5 py-6 mx-auto">
      <h4 className="title-font font-medium text-3xl text-gray-900">
        Frequently Asked Questions
      </h4>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        Is this suitable for beginners?
      </h5>
      <p className="leading-relaxed">
        The book was written with beginners in mind. It takes you through the
        process of building your first Action and workflow step by step using
        JavaScript. It explains all of the concepts you need to know, and
        provides the source code for each chapter to read at your own pace.
      </p>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        What if I don't know JavaScript?
      </h5>
      <p className="leading-relaxed">
        Don't worry! In Chapter 5 we build an action using Bash so that you can
        learn how things work under the hood without any libraries hiding the
        implementation details. With this knowledge, you'll be able to build
        actions in any language you like!
      </p>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        Can I upgrade to a more complete package later?
      </h5>
      <p className="leading-relaxed">
        Absolutely. Reply to your purchase receipt and we'll make it happen for
        you.
      </p>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        Is the book kept up to date with GitHub Actions changes?
      </h5>
      <p className="leading-relaxed">
        Of course! Actions is still a relatively new tool, which means that
        there will be new tools and best practices released regularly. You'll
        get updates free of charge as the book is updated with these changes.
      </p>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        Can I get a physical copy?
      </h5>
      <p className="leading-relaxed">
        We only offer PDF and epub versions of the book at the moment.
      </p>

      <h5 className="leading-relaxed text-2xl mt-8 font-bold">
        Can I get a refund?
      </h5>
      <p className="leading-relaxed">
        Of course! Reply to your purchase receipt within 2 weeks of purchase and
        you'll get a full refund.
      </p>
    </div>
  </Row>
)

export default Faq
