import React from "react"

import GrayRow from "../components/gray-row"
import HeaderLeft from "../components/headerleft"
import Image from "../components/image"

const Header = ({ siteTitle }) => (
  <GrayRow width="1200" category="header">
    <div className="flex flex-row">
      <div className="px-8 w-full lg:w-4/6">
        <HeaderLeft />
      </div>
      <div className="hidden lg:inline px-8 w-0 lg:w-1/6">
        <Image />
      </div>
    </div>
  </GrayRow>
)

export default Header
