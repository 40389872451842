import React from "react"

import Layout from "../components/layout"
import AuthorNote from "../components/author-note"
import MoreThanCi from "../components/more-than-ci"
import Cta from "../components/cta"
import CtaTeam from "../components/cta-team"
import Faq from "../components/faq"
import Testimonial from "../components/testimonial"
import Highlights from "../components/highlights"
import SEO from "../components/seo"
import Pricing from "../components/pricing"
import Header from "../components/header"
import Nav from "../components/nav"

import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Building GitHub Actions" />
      <Header siteTitle={data.site.siteMetadata.title} />
      <AuthorNote />
      <Testimonial />
      <MoreThanCi />
      <Highlights />
      <Pricing />
      <CtaTeam />
      <Faq />
      <Cta />
      <Nav />
    </Layout>
  )
}

export default IndexPage
