import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const GrayRow = ({ children, width, category }) => (
  <VisibilitySensor
    partialVisibility
    onChange={isVisible => {
      if (!isVisible) {
        return
      }
      trackCustomEvent({
        category,
        action: isVisible ? "visible" : "hidden",
      })
    }}
  >
    <div className="bg-gray-200 py-12 justify-center xl:justify-start xl:py-24 px-8 lg:px-12 xl:px-24">
      <div style={{ maxWidth: (width || 800) + "px" }} className="mx-auto">
        {children}
      </div>
    </div>
  </VisibilitySensor>
)

export default GrayRow
