import React from "react"
import Row from "../components/gray-row"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Cta = ({ siteTitle }) => (
  <Row width="1200" category="cta-team">
    <div className="container px-5 py-6 mx-auto flex flex-wrap items-center">
      <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
        <h4 className="title-font font-medium text-3xl text-gray-900">
          Buying for a team?
        </h4>
        <p className="leading-relaxed mt-4">
          Help your whole team learn <em>and</em> save more when purchasing a
          team license, from just $129 for a team of five!
        </p>
        <p className="leading-relaxed mt-4">
          All team licenses come with an invitation to the <em>exclusive</em>{" "}
          Slack community of experts to support your team as they build their
          actions.
        </p>
      </div>
      <div className="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        <a
          href="https://gum.co/VGlOHf"
          target="_blank"
          rel="noopener noreferrer"
          className="gumroad-button text-white bg-teal-500 border-0 py-2 px-4 focus:outline-none hover:bg-teal-600 rounded text-xl text-center"
          onClick={() =>
            trackCustomEvent({
              category: "pricing",
              action: "click",
              label: "team",
            })
          }
        >
          Purchase team licence
        </a>
      </div>
    </div>
  </Row>
)

export default Cta
