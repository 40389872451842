import React from "react"
import Row from "../components/white-row"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Pricing = ({ siteTitle }) => (
  <Row width="1200" category="pricing">
    <section className="text-gray-700 body-font overflow-hidden">
      <div className="container mx-auto">
        <div className="flex flex-col text-center w-full mb-8">
          <h1
            id="purchase"
            className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900"
          >
            Pick your package
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Includes free updates for new GitHub Actions releases
          </p>
        </div>
        <div className="flex flex-wrap -m-4 xl:w-5/6 mx-auto">
          <div className="py-4 xl:px-2 xl:w-1/3 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                BOOK ONLY
              </h2>
              <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                <span>$39</span>
              </h1>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 mt-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                The book in PDF and ePub Format
              </p>
              <p className="flex items-start text-gray-600 mb-6 ">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Source code access
              </p>
              <a
                className="gumroad-button flex items-center mt-auto text-white bg-gray-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-600 rounded"
                href="https://gum.co/ZjAMH?wanted=true"
                target="_blank"
                rel="noopener noreferrer"
                data-gumroad-single-product="true"
                onClick={() =>
                  trackCustomEvent({
                    category: "pricing",
                    action: "click",
                    label: "just-the-book",
                    value: 39,
                  })
                }
              >
                Purchase Now
              </a>
            </div>
          </div>
          <div className="py-4 xl:px-2 xl:w-1/3 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-teal-500 flex flex-col relative overflow-hidden">
              <span className="bg-teal-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                BEST VALUE
              </span>
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                FULL
              </h2>
              <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                <span>$69</span>
                <span className="text-lg ml-1 font-normal text-gray-500 line-through">
                  $99
                </span>
              </h1>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-teal-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                The book in PDF and ePub Format
              </p>

              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-teal-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Source code access
              </p>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-teal-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                <span>
                  Screencasts covering all of the exercises from the book
                </span>
              </p>
              <p className="flex items-start text-gray-600 mb-6">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-teal-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Access to a community of GitHub Actions builders
              </p>
              <a
                className="gumroad-button flex items-center mt-auto text-white bg-teal-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-teal-600 rounded"
                href="https://gum.co/ZjAMH?wanted=true&variant=The%20Complete%20Package"
                target="_blank"
                rel="noopener noreferrer"
                data-gumroad-single-product="true"
                onClick={() =>
                  trackCustomEvent({
                    category: "pricing",
                    action: "click",
                    label: "the-complete-package",
                    value: 69,
                  })
                }
              >
                Purchase Now
              </a>
            </div>
          </div>
          <div className="py-4 xl:px-2 xl:w-1/3 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                ASK THE EXPERTS
              </h2>
              <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                <span>$189</span>
                <span className="text-lg ml-1 font-normal text-gray-500 line-through">
                  $249
                </span>
              </h1>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                The book in PDF and ePub Format
              </p>

              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Source code access
              </p>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                <span>
                  Screencasts covering all of the exercises from the book
                </span>
              </p>
              <p className="flex items-start text-gray-600 mb-2">
                <span className="w-4 h-4 mt-2 mr-2 mt-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Access to a community of GitHub Actions builders
              </p>
              <p className="flex items-start text-gray-600 mb-6">
                <span className="w-4 h-4 mt-2 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                A 60 minute call with Michael to design your own Action
              </p>
              <a
                className="gumroad-button flex items-center mt-auto text-white bg-gray-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-600 rounded"
                href="https://gum.co/ZjAMH?wanted=true&variant=Ask%20The%20Experts"
                target="_blank"
                rel="noopener noreferrer"
                data-gumroad-single-product="true"
                onClick={() =>
                  trackCustomEvent({
                    category: "pricing",
                    action: "click",
                    label: "just-the-book",
                    value: 189,
                  })
                }
              >
                Purchase Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Row>
)

export default Pricing
