import React from "react"

const Project = ({ org, logo, project }) => (
  <div className="mt-8">
    <div className="md:flex md:items-center md:justify-center">
      <div className="md:flex-shrink-0">
        <img
          className="mx-auto h-10 w-10 rounded-full"
          src={"https://avatars.githubusercontent.com/u/" + logo + "?s=200&v=4"}
          alt={org + " Logo"}
        />
      </div>
      <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
        <div className="text-base leading-6 font-medium text-gray-900">
          {org}
        </div>

        <svg
          className="hidden md:block mx-1 h-5 w-5 text-graphql"
          fill="teal"
          viewBox="0 0 20 20"
        >
          <path d="M11 0h3L9 20H6l5-20z"></path>
        </svg>

        <div className="text-base leading-6 font-medium text-gray-500">
          {project}
        </div>
      </div>
    </div>
  </div>
)

export default Project
