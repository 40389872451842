import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

function emailEvent(action) {
  trackCustomEvent({
    category: "email",
    action,
  })
}

const EmailListForm = () => {
  const [email, setEmail] = useState("")
  const [completed, setCompleted] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    emailEvent("submit")

    addToMailchimp(email)
      .then(data => {
        if (data.result === "error") {
          if (data.msg.includes("is already subscribed to list")) {
            emailEvent("duplicate")
            return setError("You've already signed up")
          }
          emailEvent("error")
        } else {
          setCompleted("Sample sent")
          emailEvent("success")
        }
      })
      .catch(error => {
        console.log(error)
        emailEvent("http_error")
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  if (completed) {
    return (
      <div className="flex sm:justify-center xl:justify-start">
        <div className="text-center flex-grow max-w-xl bg-white rounded p-4 mt-2 sm:mt-8 border-2 border-teal-400">
          {completed}
        </div>
      </div>
    )
  }

  let errorBox = ""
  if (error) {
    errorBox = (
      <div className="flex sm:justify-center xl:justify-start">
        <p className="flex-grow max-w-xl bg-white rounded p-4 mt-2 sm:mt-8 border-red-400 border-2">
          {error}
        </p>
      </div>
    )
  }

  return (
    <>
      {errorBox}
      <div className="flex sm:justify-center xl:justify-start">
        <form
          className="flex-grow max-w-xl sm:bg-white rounded p-4 mt-2 sm:mt-8"
          onSubmit={handleSubmit}
        >
          <div className="flex items-center flex-col sm:flex-row">
            <input
              className="appearance-none bg-white sm:bg-transparent border-none w-full text-gray-700 mr-3 sm:py-1 sm:px-2 p-4 leading-tight focus:outline-none"
              type="email"
              placeholder="ashley@example.com"
              aria-label="Email Address"
              onChange={handleEmailChange}
              onFocus={() => emailEvent("focus")}
            />
            <button
              className="mt-4 sm:mt-0 w-full -ml-3 sm:ml-0 sm:w-auto flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
              type="submit"
            >
              Get a free sample
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EmailListForm
