import React from "react"

const Nav = ({ siteTitle }) => (
  <div id="nav" className="flex justify-center px-4 text-gray-100 bg-gray-800">
    <div className="container py-6">
      <div className="flex flex-col items-center justify-between md:flex-row">
        <div className="hidden md:block">
          <a href="/" className="text-xl font-bold">
            Building GitHub Actions
          </a>
        </div>
        <div className="flex md:m-0">
          <div className="md:-mx-4">
            <a href="/#purchase" className="pr-8 text-sm">
              Purchase
            </a>
            <a href="/#subscribe" className="pr-8 text-sm">
              Subscribe
            </a>
            <a href="/toc" className="hidden md:inline pr-8 text-sm">
              Table of Contents
            </a>
            <a href="/toc" className="md:hidden pr-8 text-sm">
              TOC
            </a>
            <a href="/privacy" className="pr-8 text-sm">
              Privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Nav
