import React from "react"
import GrayRow from "../components/gray-row"
import Project from "../components/project"

const Testimonial = ({ siteTitle }) => (
  <GrayRow category="testimonial">
    <blockquote className="text-2xl text-center">
      Trusted by the following projects (and more!):
    </blockquote>

    <div class="grid grid-cols-2 gap-4">
      <Project org="brave" logo="12301619" project="brave-core-crx-packager" />
      <Project org="vimeo" logo="317769" project="psalm" />
      <Project org="weaveworks" logo="9976052" project="weave-gitops" />
      <Project org="Semantic-Org" logo="5796209" project="Semantic-UI-React" />
      <Project
        org="gitlabhq"
        logo="1086321"
        project="terraform-provider-gitlab"
      />
      <Project org="redwoodjs" logo="45050444" project="redwood" />
      <Project org="rust-unofficial" logo="20820960" project="awesome-rust" />
      <Project org="PHPOffice" logo="1836015" project="PHPWord" />
    </div>
  </GrayRow>
)

export default Testimonial
