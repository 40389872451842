import React from "react"
import Row from "../components/gray-row"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Highlights = ({ siteTitle }) => (
  <Row width="1600" category="highlights">
    <div className="text-center mb-16">
      <h4 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
        What's in the book
      </h4>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
        Or view the full{" "}
        <a
          href="/toc"
          onClick={() =>
            trackCustomEvent({
              category: "highlights",
              action: "click",
              label: "toc",
            })
          }
        >
          table of contents
        </a>
      </p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 justify-center">
      <div className="py-8 px-4 md:p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center bg-white rounded-lg border-teal-500 border-2 mx-2">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-teal-100 text-teal-500 mb-5 flex-shrink-0">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-10 h-10"
            viewBox="0 0 24 24"
          >
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
            Build your first Action
          </h2>
          <p className="leading-relaxed text-base">
            Learn how to build a GitHub Action, customise it with inputs, and
            build a workflow to run the action in response to various triggers
            in the GitHub ecosystem
          </p>
        </div>
      </div>
      <div className="py-8 px-4 md:p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center bg-white rounded-lg border-teal-500 border-2 mx-2">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-teal-100 text-teal-500 mb-5 flex-shrink-0">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-10 h-10"
            viewBox="0 0 24 24"
          >
            <circle cx="6" cy="6" r="3"></circle>
            <circle cx="6" cy="18" r="3"></circle>
            <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
          </svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
            Testing Actions locally
          </h2>
          <p className="leading-relaxed text-base">
            The feedback cycle of <em>commit, push, wait</em> takes too long
            when you're building an Action. Learn how to test complete workflows
            locally, and how to write unit tests for your actions.
          </p>
        </div>
      </div>
      <div className="py-8 px-4 md:p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center bg-white rounded-lg border-teal-500 border-2 mx-2">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-teal-100 text-teal-500 mb-5 flex-shrink-0">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-10 h-10"
            viewBox="0 0 24 24"
          >
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
            Maintainance Tips and Tricks
          </h2>
          <p className="leading-relaxed text-base">
            Writing an action isn't where the story ends. Learn how to document,
            version and debug your actions as you build a community of users
          </p>
        </div>
      </div>
    </div>
  </Row>
)

export default Highlights
